import * as React from "react";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import MenuItem from "./menu";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const variants = {
  open: {
    visibility: 'visible', 
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    visibility: 'hidden',
    transition: {delay: 0.5, staggerChildren: 0.05, staggerDirection: -1 }
  }
};

export const NavigationBar = ({toggle, isMob}) => (
  <motion.ul variants={variants} className={`z-[2] menuUL ${isMob ? 'left-6': ''}`}>
    {itemIds.map((item,i) => (
      <MenuItem i={i} key={i} toggle={toggle} ><Link  to={item.url ?? ''}>{item.title ?? item}</Link></MenuItem>
    ))}
  </motion.ul>
);

const itemIds = [{title: 'Home', url: '/'}, {title: 'Case Studies', url: '/case-studies'},{title:'Services', url: '/#services'} , {title:'Team', url: '/our-team'} , {title: 'Contact Us', url: '/#contact'}];

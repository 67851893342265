import * as React from "react";
import { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "./use-dimensions";
import MenuToggle from "./menuToggle";
import { NavigationBar } from "./navigationBar";
import { MobileMenuToggle } from "./menuToggle";



export const HumbergMenu = () => {
 
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);


  const sidebar =  {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2
      }
    }),
    closed: {
      clipPath: "circle(30px at 46px 38px)",
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40
      }
    }
  };


  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
    >
      <motion.div className="background-nav" variants={sidebar} />
      <NavigationBar toggle={() => toggleOpen()}/>
      <MenuToggle toggle={() => toggleOpen()} open={isOpen} />
    </motion.nav>
  );
};

export const MobileHamburg = () => {
 
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);


  const sidebar =  {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at calc(100% - 46px) 40px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2
      }
    }),
    closed: {
      clipPath: "circle(30px at calc(100% - 46px) 38px)",
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40
      }
    }
  };


  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
    >
      <motion.div className="background-nav" variants={sidebar} />
      <NavigationBar isMob={true} toggle={() => toggleOpen()}/>
      <MobileMenuToggle toggle={() => toggleOpen()} open={isOpen} />
    </motion.nav>
  );
};

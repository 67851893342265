"use client";
import React, { useEffect } from "react";
import {Link} from 'gatsby'
import { HumbergMenu } from "../menuBar/humberMenu";
import CreativeButton from "../buttons/creative";
import {
  useScroll,
  motion,
  useTransform,
  useMotionValue,
  useMotionTemplate,
} from "framer-motion";
import {  StaticImage } from 'gatsby-plugin-image';
import { MobileHamburg } from "../menuBar/humberMenu";

const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

function useBoundedScroll(bounds) {
  const { scrollY } = useScroll();
  const scrollBounded = useMotionValue(0);
  let scrollBoundedProgress = useTransform(scrollBounded, [0, bounds], [0, 1]);

  useEffect(() => {
    return scrollY.on("change", (current) => {
      let previous = scrollY.getPrevious();
      let diff = current - previous;
      let newScrollBounded = scrollBounded.get() + diff;

      scrollBounded.set(clamp(newScrollBounded, 0, bounds));
    });
  }, [scrollY, scrollBounded, bounds]);

  return { scrollBounded, scrollBoundedProgress };
}

export default function Nav({logo}) {
  const { scrollBoundedProgress } = useBoundedScroll(200);
  const scrollThrottleProgress = useTransform(
    scrollBoundedProgress,
    [0, 0.75, 1],
    [0, 0, 1]
  );
  let height = useTransform(scrollThrottleProgress, [0, 1], [80, 50]);
  let opacity = useTransform(scrollThrottleProgress, [0, 1], [1, 0]);

  return (
    <motion.header
      style={{
        height,
        backgroundColor: useMotionTemplate`rgb(18, 20, 29, ${useTransform(
          scrollThrottleProgress,
          [0, 1],
          [1, 0.1]
        )})`,
      }}
      className="text-white flex justify-between py-3 px-6 items-center z-20 fixed inset-x-0 shadow backdrop-blur-md"
    >
      <motion.div className="sm:block hidden" style={{ opacity }}>
        <HumbergMenu />
      </motion.div>
      <motion.div
        className="z-20"
        style={{
          scale: useTransform(scrollThrottleProgress, [0, 1], [1, 0.8]),
        }}
      >
        <Link to="/" className="sm:w-36 w-28 h-auto inline-block">
          
          <StaticImage src="../../images/logo.png" alt="logo img"/>
        </Link>
      </motion.div>
      <motion.div style={{ opacity }} className="z-20 hidden sm:block">
        <CreativeButton link="/#contact">Contact Us</CreativeButton>
      </motion.div>

      <motion.div className="sm:hidden" style={{ opacity }}>
        <MobileHamburg />
      </motion.div>
    </motion.header>
  );
}

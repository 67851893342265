import React from "react";

export default function Footer() {
  return (
    <footer className="pb-6 text-white bg-[#12141d] border-t border-accent/60 py-8">
      
      <div className="text-center">
        <span className="text-sm text-slate-400">
          &copy; {new Date().getFullYear()} Node. All rights reserved.
        </span>
      </div>
    </footer>
  );
}

{/* <div className="border-t border-accent text-center  flex justify-center ">
        <ul className="flex gap-10 items-center">
          <li>About</li>
          <li>Shopify</li>
          <li>Wordpress</li>
          <li>Headless</li>
          <li>Contact US</li>
        </ul>
      </div> */}
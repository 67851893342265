import React from "react";
import Nav from "./nav";
import Footer from './footer';

// const DMSans = DM_Sans({ subsets: ["latin"], weight: ["400", "500", "700"] });

// export const metadata = {
//   title: "Node Agency",
//   description:
//     ": Leading Web Development Agency for Shopify, Wordpress and Dynamic Websites",
//   keywords: [
//     "Node Agency",
//     "Web Development",
//     "Shopify Developer",
//     "Headless Shopify",
//     "Wordpress",
//   ],
//   icons: {
//     icon: "/favicon.ico",
//     shortcut: "/favicon-16x16.png",
//     apple: "/apple-touch-icon.png",
//   },
// };

export default function Layout({ children, logo }) {
  return (
    <>
        <Nav logo={logo}/>
         {children}
        <Footer />
      </>
  );
}

import React from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export default function CreativeButton({children, link}){
    return (
        <>
        <AnchorLink className="px-4 rounded-full font-bold text-sm tracking-wider inline-flex creativeBtn" to={link}><span className="link_text">{children}</span></AnchorLink>
        </>
    )
}
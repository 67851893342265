import React from 'react';




export default function MenuToggle({toggle, open}){

    return (
        <div onClick={toggle} id="menu" className="flex items-center gap-4 text-sm font-bold tracking-[5px] cursor-pointer relative z-20 w-[188px]">
        <div className="hamburger relative w-[45px] h-[50px] flex items-center">
            <div className={`line-1 ${open && 'active'}`}></div>
            <div className={`line-2 ${open && 'active'}`}></div>
        </div>
        <div className="menu-text-wrapper relative">
            <div className={`menuText ${open && 'hideMenu'}`}>Menu</div>
            <div className={`closeText ${open && 'active'}`}>Close</div>
        </div>
    </div>
    )
}

export function MobileMenuToggle({toggle, open}){

    return (
        <div onClick={toggle} id="menu" className="flex sm:flex-row flex-row-reverse items-center gap-4 text-sm font-bold tracking-[5px] cursor-pointer relative z-20 max-w-[188px]">
        <div className="hamburger relative w-[45px] h-[50px] flex items-center">
            <div className={`line-1 ${open && 'active'}`}></div>
            <div className={`line-2 ${open && 'active'}`}></div>
        </div>
        <div className="menu-text-wrapper relative">
            <div className={`menuText ${open && 'hideMenu'}`}>Menu</div>
            <div className={`closeText ${open && 'active'}`}>Close</div>
        </div>
    </div>
    )
}